import { Injectable } from '@angular/core';
import { Empty } from '@bufbuild/protobuf';
import { injectLibrarianDiscoverClient } from '@frontend2/api';
import { AuxiliaryCategories_Category } from '@frontend2/proto/librarian/proto/discover_pb';
import { CacheBloc } from '../bloc';

export type AuxiliaryCategory = AuxiliaryCategories_Category;

@Injectable({ providedIn: 'root' })
export class AuxiliaryCategoriesCache extends CacheBloc<AuxiliaryCategory[]> {
  readonly discoverClient = injectLibrarianDiscoverClient();

  constructor() {
    super([]);
  }

  async fetch(): Promise<AuxiliaryCategory[]> {
    const response = await this.discoverClient.getAuxiliaryCategories(
      new Empty(),
    );

    return response.categories;
  }

  getName(id: number): string | null {
    if (this.isLoaded()) {
      const category = this.cachedData().find((c) => c.id === id);
      return category ? category.name : null;
    }
    return null;
  }

  getCategory(id: number): AuxiliaryCategory | null {
    if (this.isLoaded()) {
      return this.cachedData().find((c) => c.id === id) || null;
    }
    return null;
  }
}
