<form
  class="grid"
  [formGroup]="formModel"
>
  <div class="col-12">
    <workspace-autocomplete
      formControlName="client"
      label="Team"
      (valueChange)="client.set($event)"
    ></workspace-autocomplete>
  </div>
</form>
