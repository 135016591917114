import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Int32Value, Int64Value, Timestamp } from '@bufbuild/protobuf';
import { BroncoTaskError, injectLibrarianCWAdminClient } from '@frontend2/api';
import {
  Networks,
  capitalize,
  getEnumValues,
  isGhostReport,
  isGhostTeam,
  isNil,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import { readableReportPriority } from '@frontend2/proto-helpers/librarian/competitive_watch_pb.helpers';
import { CampaignSnippet } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  AutocompleteCampaignsRequest,
  AutocompleteCwBrandsRequest,
  CWReportDetails,
  CWReportRequest,
  CWUpdateCategorizeReportReq,
} from '@frontend2/proto/librarian/proto/admin_cw_pb';
import { TeamsResponse_TeamLight } from '@frontend2/proto/librarian/proto/admin_pb';
import {
  CWBrandPb,
  ReportPriority,
} from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import {
  BroncoService,
  DialogBase,
  LeftyValidators,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  injectToastManager,
  showToastException,
} from '@frontend2/ui';

import {
  LeftyButtonDirective,
  LeftyChipsAutocompleteComponent,
  LeftyDatePickerComponent,
  LeftyDialogComponent,
  LeftyFormAutocompleteComponent,
  LeftyFormInputComponent,
  LeftyFormNumberInputComponent,
  LeftyFormSelectComponent,
  LeftySpinnerComponent,
  LeftyToggleComponent,
  NetworkFormSelectComponent,
} from '@frontend2/ui';
import { debounceTime } from 'rxjs';
import { FlandersPermissionService } from '../../../permission.service';
import { WorkspaceAutoCompleteComponent } from '../workspace-autocomplete/workspace-autocomplete.component';

@Component({
  selector: 'create-or-edit-report-dialog',
  templateUrl: 'create-or-edit-report-dialog.component.html',
  styleUrls: ['create-or-edit-report-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftySpinnerComponent,
    FormsModule,
    ReactiveFormsModule,
    NetworkFormSelectComponent,
    LeftyFormInputComponent,
    LeftyDatePickerComponent,
    WorkspaceAutoCompleteComponent,
    LeftyFormAutocompleteComponent,
    LeftyChipsAutocompleteComponent,
    LeftyFormNumberInputComponent,
    LeftyToggleComponent,
    LeftyFormSelectComponent,
    LeftyButtonDirective,
  ],
})
export class CreateOrEditReportDialogComponent extends DialogBase {
  constructor() {
    super();

    toObservable(this.brandSearchValue)
      .pipe(debounceTime(300), takeUntilDestroyed())
      .subscribe((val) => this.searchBrands(val));

    toObservable(this.campaignSearchValue)
      .pipe(debounceTime(300), takeUntilDestroyed())
      .subscribe((val) => this.searchCampaigns(val));
  }

  private readonly toastManager = injectToastManager();
  private readonly broncoService = inject(BroncoService);
  private readonly librarianCWAdmin = injectLibrarianCWAdminClient();
  readonly permissionService = inject(FlandersPermissionService);

  readonly success$ = output<CWReportDetails>();

  readonly report = signal(new CWReportDetails());

  readonly supportedNetworks = computed(() => {
    const basicNetworks = Networks.supportedCW;
    return this.permissionService.hasSnapchatOnCwSet()
      ? [...basicNetworks, Network.SNAPCHAT]
      : basicNetworks;
  });

  readonly brandOptions = signal<CWBrandPb[]>([]);
  readonly campaignOptions = signal<CampaignSnippet[]>([]);

  readonly brandSearchValue = signal('');
  readonly campaignSearchValue = signal('');

  readonly priorityOptions = getEnumValues<ReportPriority>(
    ReportPriority,
  ).filter((p) => p !== ReportPriority.UNDEFINED);

  readonly loadingSubmit = signal(false);
  readonly loadingReport = signal(false);
  readonly autocompleteLoading = signal(false);

  readonly networks = computed(() => this.report().networks);

  readonly isEditing = computed(() => !isGhostReport(this.report()));

  readonly title = computed(() =>
    this.isEditing()
      ? `Edit "${this.report().reportName}" report`
      : 'Create report',
  );

  readonly reportForm: FormGroup = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    team: new FormControl<TeamsResponse_TeamLight | null>(
      null,
      Validators.required,
    ),
    networks: new FormControl<Network[]>([], LeftyValidators.requiredIterable),
    brand: new FormControl<CWBrandPb | null>(null, Validators.required),
    campaigns: new FormControl<CampaignSnippet[]>(
      [],
      LeftyValidators.requiredIterable,
    ),
    startDate: new FormControl<Date | null>(null, Validators.required),
    expiryDate: new FormControl<Date | null>(null),
    minFollowers: new FormControl<number | null>(
      null,
      this.minFollowersValidator,
    ),
    isCategorizing: new FormControl<boolean>(false),
    priority: new FormControl<ReportPriority>(ReportPriority.VERY_LOW),
  });

  minFollowersValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value === null) {
      return null;
    }

    const val = control.value;
    if (val < 10000) {
      return {
        invalid: 'Must be >= 10000',
      };
    }
    return null;
  }

  readableReportPriority(val: ReportPriority): string {
    return readableReportPriority(val);
  }

  //if we open the dialog from report listing, the influencerCategorizing is not available, so we need to get details, in that case we open with id
  async openWith(newReport?: CWReportDetails | bigint): Promise<void> {
    this.open();
    if (newReport instanceof CWReportDetails) {
      this.report.set(newReport);
    } else if (isNotNil(newReport)) {
      this.loadingReport.set(true);
      const report = await this.librarianCWAdmin.cWGetReportDetails(
        new Int64Value({ value: newReport }),
      );
      this.loadingReport.set(false);
      this.report.set(report);
    } else {
      this.report.set(new CWReportDetails());
    }

    this.populateForm(this.report());
  }

  populateForm(report: CWReportDetails): void {
    const team = isGhostTeam(report?.team)
      ? undefined
      : new TeamsResponse_TeamLight({
          id: report.team?.id,
          name: report.team?.name,
          owner: report.team?.owner,
        });

    this.reportForm.patchValue({
      name: report.reportName,
      team: team,
      networks: this.isEditing() ? report.networks : [Network.INSTA],
      brand: report.referenceBrand,
      campaigns: report.campaigns,
      startDate: report.start ? report.start.toDate() : undefined,
      expiryDate: report.expiryDate ? report.expiryDate.toDate() : undefined,
      minFollowers: report.minFollowers,
      isCategorizing: report.categorizeInfluencers ?? false,
      priority:
        report.priority === ReportPriority.UNDEFINED
          ? undefined
          : report.priority,
    });

    this.brandSearchValue.set(
      report.referenceBrand ? this.brandRenderer(report.referenceBrand) : '',
    );
  }

  async searchBrands(brandText: string): Promise<void> {
    this.autocompleteLoading.set(true);
    const resp = await this.librarianCWAdmin.autocompleteCWBrands(
      new AutocompleteCwBrandsRequest({
        value: brandText,
        networks: this.reportForm.get('networks')?.value,
      }),
    );
    this.brandOptions.set(resp.brands);

    this.autocompleteLoading.set(false);
  }

  async searchCampaigns(campaignText: string): Promise<void> {
    this.autocompleteLoading.set(true);
    const resp = await this.librarianCWAdmin.autocompleteCWCampaigns(
      new AutocompleteCampaignsRequest({
        value: campaignText,
        excludeIds: this.reportForm
          .get('campaigns')
          ?.value?.map((c: CampaignSnippet) => c.id),
      }),
    );
    this.campaignOptions.set(resp.campaigns);
    this.autocompleteLoading.set(false);
  }

  async submit(): Promise<void> {
    if (this.reportForm.invalid) {
      return;
    }
    this.loadingSubmit.set(true);
    const request = this._buildRequest();

    const toastSuccessMsg = this.isEditing()
      ? `Report <b>${this.report().reportName}</b> edited with success`
      : 'Report created with success';

    const toastProgressMsg = this.isEditing()
      ? `Editing <b>${this.report().reportName}</b> report`
      : 'Creating report';

    const toastErrorMsg = this.isEditing()
      ? `Failed to edit <b>${this.report().reportName}</b> report`
      : 'Failed to create report';

    const toast = this.toastManager.showLoading(toastProgressMsg);

    try {
      const response =
        await this.librarianCWAdmin.cWCreateOrEditReport2(request);

      const token = response.token;
      const newReport = response.reportDetails;
      const formCategorizingValue = this.reportForm.value.isCategorizing;

      if (isNotEmptyString(token)) {
        const broncoSubscription =
          this.broncoService.subscribeWithoutToast(token);
        this.close();
        const task = await broncoSubscription.wait();
        const failures = task.output ? JSON.parse(task.output)['failures'] : [];
        if (failures && failures.length > 0) {
          this.toastManager.update(toast.id, {
            text: toastErrorMsg,
            type: TOAST_TYPE_ERROR,
          });
          return;
        }
      }

      if (
        this.report().categorizeInfluencers !== formCategorizingValue &&
        isNotNil(newReport)
      ) {
        await this._updateCategorizing(
          newReport?.reportId ?? BigInt(0),
          formCategorizingValue,
          this.isEditing(),
        );

        newReport.categorizeInfluencers = formCategorizingValue;
      }

      this.toastManager.update(toast.id, {
        text: toastSuccessMsg,
        type: TOAST_TYPE_SUCCESS,
      });

      if (newReport) {
        this.success$.emit(newReport);
        this.close();
      }
    } catch (e: unknown) {
      if (e instanceof BroncoTaskError) {
        this.toastManager.update(toast.id, {
          text: toastErrorMsg,
          type: TOAST_TYPE_ERROR,
        });
      } else {
        this.toastManager.close(toast.id);
        showToastException(this.toastManager, e);
      }
    } finally {
      this.loadingSubmit.set(false);
    }
  }

  private _buildRequest(): CWReportRequest {
    const formValue = this.reportForm.value;
    const request = new CWReportRequest({
      name: formValue.name,
      networks: formValue.networks,
      referenceBrand: formValue.brand.brandId,
      campaignIds: formValue.campaigns.map((c: CampaignSnippet) => c.id),
      userId: formValue.team.owner?.userId,
      startDate: Timestamp.fromDate(formValue.startDate),
      expiryDate: isNotNil(formValue.expiryDate)
        ? Timestamp.fromDate(formValue.expiryDate)
        : undefined,
      priority: formValue.priority,
    });

    if (this.isEditing()) {
      request.reportId = BigInt(this.report().reportId);
    }

    const minFollowers = formValue.minFollowers;
    if (isNil(minFollowers)) {
      request.minFollowersOf = {
        value: true,
        case: 'clearMinFollowers',
      };
    } else {
      request.minFollowersOf = {
        value: new Int32Value({ value: minFollowers }),
        case: 'minFollowers',
      };
    }
    return request;
  }

  private async _updateCategorizing(
    reportId: bigint,
    categorize: boolean,
    isEditing: boolean,
  ): Promise<void> {
    if ((!isEditing && !categorize) || reportId === BigInt(0)) {
      return;
    }
    const req = new CWUpdateCategorizeReportReq({
      reportId: reportId,
      doCategorize: categorize,
    });
    await this.librarianCWAdmin.cWUpdateCategorizeInfluencersInReport(
      new CWUpdateCategorizeReportReq(req),
    );
  }

  networkRenderer(network: Network): string {
    return capitalize(Networks.readable(network));
  }

  campaignRenderer(campaign: CampaignSnippet): string {
    return `${campaign.id} - ${campaign.name}`;
  }

  brandRenderer(brand: CWBrandPb): string {
    return brand.name;
  }

  override close(): void {
    super.close();
    this.reportForm.reset();
    this.brandSearchValue.set('');
    this.campaignSearchValue.set('');
    this.report.set(new CWReportDetails());
  }
}
