<lefty-dialog
  [autoDismissable]="true"
  (close$)="close()"
  [(visible)]="visible"
>
  <h3 title>
    @if (loadingReport()) {
      Edit report
    } @else {
      {{ title() }}
    }
  </h3>
  @if (loadingReport()) {
    <div class="loading-report-container">
      <lefty-spinner></lefty-spinner>
    </div>
  } @else {
    <form
      [formGroup]="reportForm"
      class="grid"
    >
      <div class="col-4 fill-space">
        <network-form-select
          label="Networks"
          formControlName="networks"
          [networks]="supportedNetworks()"
          [isMultiSelect]="true"
        ></network-form-select>
      </div>
      <div class="col-12">
        <lefty-form-input
          formControlName="name"
          label="Report name"
        ></lefty-form-input>
      </div>
      <div class="col-4">
        <lefty-date-picker
          #startDate
          label="Start date"
          formControlName="startDate"
        >
        </lefty-date-picker>
      </div>
      <div class="col-4 fill-space">
        <lefty-date-picker
          label="Expiry date"
          formControlName="expiryDate"
          [minDate]="startDate.value"
          [clearable]="!isEditing()"
        >
        </lefty-date-picker>
      </div>
      <div class="col-12">
        <workspace-autocomplete
          formControlName="team"
          name="team"
          label="Reference Team"
        ></workspace-autocomplete>
      </div>
      <div class="col-12">
        <lefty-form-autocomplete
          (inputTextChange)="brandSearchValue.set($event)"
          [options]="brandOptions()"
          [inputText]="brandSearchValue()"
          [loading]="autocompleteLoading()"
          formControlName="brand"
          name="brand"
          label="Reference brand"
          [noFiltering]="true"
          [itemRenderer]="brandRenderer"
        ></lefty-form-autocomplete>
      </div>
      <div class="col-12">
        <lefty-chips-autocomplete
          formControlName="campaigns"
          label="Reference campaigns"
          [options]="campaignOptions()"
          (inputTextChange)="campaignSearchValue.set($event)"
          [inputText]="campaignSearchValue()"
          [itemRenderer]="campaignRenderer"
          [loading]="autocompleteLoading()"
        ></lefty-chips-autocomplete>
      </div>
      <div class="col-12">
        <lefty-form-number-input
          formControlName="minFollowers"
          [optional]="true"
          label="Min followers"
          type="number"
        >
        </lefty-form-number-input>
      </div>
      <div class="col-12">
        <p>Categorizing</p>
        <div class="flex row vCenter body-1 categorizing-text">
          <span class="fill-space"> </span>

          <lefty-toggle formControlName="isCategorizing"> </lefty-toggle>
        </div>
      </div>
      <div class="col-4">
        <lefty-form-select
          formControlName="priority"
          label="Priority"
          [options]="priorityOptions"
          [itemRenderer]="readableReportPriority"
          placeholder="Select report priority"
        ></lefty-form-select>
      </div>
    </form>
  }

  <ng-container footer>
    <button
      class="left-icon"
      (click)="close()"
    >
      Cancel
    </button>
    <button
      leftyButton="primary"
      [loading]="loadingSubmit()"
      type="button"
      [disabled]="loadingSubmit() || reportForm.invalid"
      (click)="submit()"
    >
      save
    </button>
  </ng-container>
</lefty-dialog>
