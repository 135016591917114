import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Inject,
  input,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  LibrarianCWAdminClient,
  LibrarianCWAdminClientProvider,
} from '@frontend2/api';
import {
  DirectorySourceRequest,
  SourceRequest,
} from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import { SourceRequestForm } from '../source-forms';

import { isNotNil } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { TeamsResponse_TeamLight } from '@frontend2/proto/librarian/proto/admin_pb';
import { WorkspaceAutoCompleteComponent } from '../../workspace-autocomplete/workspace-autocomplete.component';

@Component({
  selector: 'directory-source-form',
  templateUrl: 'directory-source-form.component.html',
  styleUrls: ['./../source-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, WorkspaceAutoCompleteComponent],
})
export class CwDirectorySourceFormComponent extends SourceRequestForm {
  constructor(
    ngControl: NgControl,
    @Inject(LibrarianCWAdminClientProvider)
    protected librarian: LibrarianCWAdminClient,
  ) {
    super(ngControl);

    effect(() => {
      const client = this.client();
      if (isNotNil(client)) {
        this.handleValueChange(
          this._buildSourceRequest(client, this.network()),
        );
      }
    });
  }

  override formModel = new FormGroup(
    {
      client: new FormControl<TeamsResponse_TeamLight | null>(null),
    },
    this._inputsValidator,
  );

  readonly network = input.required<Network>();

  readonly client = signal<TeamsResponse_TeamLight | undefined>(undefined);

  private _inputsValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | null {
    const client = control.get('client')?.value as TeamsResponse_TeamLight;
    if (isNotNil(client?.owner)) {
      return null;
    }
    return { required: true };
  }
  private _buildSourceRequest(
    client: TeamsResponse_TeamLight,
    network: Network,
  ): SourceRequest | undefined {
    const request = new DirectorySourceRequest({
      network: network,
      userId: client.owner?.userId,
    });

    return new SourceRequest({
      request: { value: request, case: 'directory' },
    });
  }
}
