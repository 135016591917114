import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { isNotNil } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  ManualSourceRequest,
  SourceRequest,
} from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import { LeftyChipsEditorComponent } from '@frontend2/ui';
import { SourceRequestForm } from '../source-forms';

@Component({
  selector: 'manual-source-form',
  templateUrl: 'manual-source-form.component.html',
  styleUrls: ['./../source-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, LeftyChipsEditorComponent],
})
export class CwManualSourceFormComponent extends SourceRequestForm {
  constructor(ngControl: NgControl) {
    super(ngControl);

    this.formModel.valueChanges.subscribe(() =>
      this.handleValueChange(this._buildSourceRequest(this.formModel)),
    );

    toObservable(this.network)
      .pipe(takeUntilDestroyed())
      .subscribe(() =>
        this.handleValueChange(this._buildSourceRequest(this.formModel)),
      );
  }
  readonly network = input.required<Network>();

  override formModel = new FormGroup({
    usernames: new FormControl<string[]>([]),
  });

  private _buildSourceRequest(controls: FormGroup): SourceRequest | undefined {
    if (this.formModel.invalid) {
      return undefined;
    }
    const request = new ManualSourceRequest();
    const usernames = controls.get('usernames')?.value as string[];
    if (isNotNil(usernames)) {
      request.handles = usernames;
      request.network = this.network();
    }
    return new SourceRequest({
      request: { value: request, case: 'manual' },
    });
  }
}
