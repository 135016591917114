import { computed, inject, Injectable } from '@angular/core';
import { LeftyAuthBloc } from '@frontend2/api';
import { isSuperAdmin } from '@frontend2/core';

//TODO Parisa: move all permissions here
@Injectable({ providedIn: 'root' })
export class FlandersPermissionService {
  private readonly auth = inject(LeftyAuthBloc);

  readonly user = this.auth.$user;

  readonly USERS_WITH_MERGE_REQUEST_VALIDATION_PERMISSION = [
    'liz@lefty.io',
    'andrea@lefty.io',
  ];

  readonly USERS_WITH_SNAPCHAT_ON_CW_SET = [
    'marianne@lefty.io',
    'laura@lefty.io',
    'luli@lefty.io',
    'chuhan@lefty.io',
  ];

  readonly canValidateMergeRequests = computed(() => {
    const user = this.user();
    return (
      isSuperAdmin(user) ||
      this.USERS_WITH_MERGE_REQUEST_VALIDATION_PERMISSION.includes(user.email)
    );
  });

  readonly hasSnapchatOnCwSet = computed(() => {
    const user = this.user();
    return (
      isSuperAdmin(user) ||
      this.USERS_WITH_SNAPCHAT_ON_CW_SET.includes(user.email)
    );
  });
}
